import {
	createContext,
	FC,
	PropsWithChildren,
	ReactNode,
	useCallback,
	useContext,
	useMemo,
	useState
} from 'react';
import { Dialog, DialogProps } from '@mui/material';
import { Transition } from '@/ui/theme/Transition';
import { zIndex } from '@/shared';

type DialogOptions = {
	component: ReactNode;
	dialogProps?: Omit<DialogProps, 'open'>;
};

type DialogProperties = {
	open: (opts: DialogOptions) => void;
	isOpen: boolean;
	close: () => void;
};

const dialogContext = createContext<DialogProperties>({
	open: () => {},
	isOpen: false,
	close: () => {}
});

export const useDialog = (): DialogProperties => useContext(dialogContext);

const nullishDialogOpts: DialogOptions = {
	component: null
};

export const DialogProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [dialogOpts, setDialogOpts] = useState<DialogOptions>(nullishDialogOpts);

	const closeDialog = useCallback(() => {
		setIsOpen(false);
	}, []);

	const openDialog = useCallback((opts: DialogOptions) => {
		setIsOpen(true);
		setDialogOpts(opts);
	}, []);

	const contextValue = useMemo<DialogProperties>(
		() => ({
			open: openDialog,
			close: closeDialog,
			isOpen
		}),
		[closeDialog, isOpen, openDialog]
	);

	return (
		<dialogContext.Provider value={contextValue}>
			<Dialog
				open={isOpen}
				onClose={closeDialog}
				TransitionComponent={Transition}
				transitionDuration={450}
				disableScrollLock
				sx={{
					zIndex: zIndex.dialog,
					'& .MuiDialog-paper': {
						minWidth: '50%'
					},
					...dialogOpts?.dialogProps?.sx
				}}
				{...dialogOpts?.dialogProps}>
				{dialogOpts.component}
			</Dialog>
			{children}
		</dialogContext.Provider>
	);
};
