import { FC, useState } from 'react';
import { primary } from '@/ui/theme/components-overrides/colors';
import { FlexCard } from '@/ui/flex-card/FlexCard';
import { Shuttlecock } from '@/assets/icons/generated';
import Typography from '@mui/material/Typography';
import { useTranslation } from '@/config';

export type FallbackProps = {
	onError: () => void;
	onSuccess: () => void;
};

export const DefaultFallback: FC = () => {
	const { t } = useTranslation();
	return (
		<FlexCard
			width={'100%'}
			border={{ xs: 'none', md: `1px solid ${primary[100]}` }}
			padding={{ md: 4 }}>
			<Shuttlecock height={'100px'} width={'100px'} />
			<Typography variant={'h5'}>{t('shared.something-went-wrong')}</Typography>
			<Typography variant={'h7'} textAlign={'center'}>
				{t('shared.data-fetching-problem')}
			</Typography>
		</FlexCard>
	);
};

export const withFallback = <T extends FallbackProps>(
	Component: FC<T>,
	FallbackComponent: FC = DefaultFallback
): FC<Omit<T, 'onSuccess' | 'onError'>> => {
	const FallbackableComponent: FC<Omit<T, 'onError' | 'onSuccess'>> = ({ ...rest }) => {
		const [error, setError] = useState<boolean>();

		const componentProps: T = {
			...rest,
			onError: () => setError(true),
			onSuccess: () => setError(false)
		} as T;

		return error ? <FallbackComponent /> : <Component {...componentProps} />;
	};

	return FallbackableComponent;
};
