import { isNil, isObject, Maybe, zIndex } from '@/shared';
import { Drawer, DrawerProps } from '@mui/material';
import {
	createContext,
	createRef,
	FC,
	PropsWithChildren,
	ReactNode,
	RefObject,
	useCallback,
	useContext,
	useMemo,
	useReducer
} from 'react';
import { Subscription, timer } from 'rxjs';
import DrawerBodyContainer from '@/ui/drawer/DrawerBodyContainer';
import { DrawerFooterContainer } from '@/ui/drawer/DrawerFooterContainer';
import { DrawerHeader } from '@/ui/drawer/DrawerHeader';
import { areObjectsEqual } from '@/shared/utils/helpers/object/are-objects-equal';

export type DrawerOptions = {
	drawerBodyComponent: ReactNode;
	/**
	 * @deprecated drawerFooterComponent should not be used. Footer should be included in drawerBodyComponent
	 */
	drawerFooterComponent?: ReactNode;
	drawerProps?: Omit<DrawerProps, 'onClose' | 'open'> & {
		closeCallback?: () => void;
	};
	closeXButton?: boolean;
};

type CloseOptions = {
	omitTimeout?: boolean;
};

type DrawerProperties = {
	open: (opts: DrawerOptions) => void;
	isOpen: boolean;
	bodyRef?: RefObject<HTMLElement>;
	close: (opts?: CloseOptions) => void;
	closeAll: (opts?: CloseOptions) => void;
};

const drawerContext = createContext<DrawerProperties>({
	open: () => {},
	isOpen: false,
	close: () => {},
	closeAll: () => {}
});

export const useDrawer = (): DrawerProperties => useContext(drawerContext);

type DrawerState = {
	activeDrawerOptions: Maybe<DrawerOptions>;
	drawerOptionsList: DrawerOptions[];
	isOpen: boolean;
};

const initialState: DrawerState = {
	activeDrawerOptions: undefined,
	drawerOptionsList: [],
	isOpen: false
};

type DrawerAction =
	| { type: 'open'; payload: DrawerOptions }
	| { type: 'close' | 'closeAll'; payload?: undefined };

const drawerReducer = (state: DrawerState, action: DrawerAction) => {
	switch (action.type) {
		case 'open':
			return {
				...state,
				isOpen: true,
				activeDrawerOptions: action.payload,
				drawerOptionsList: [...state.drawerOptionsList, action.payload]
			};
		case 'close':
			return {
				...state,
				isOpen: state.drawerOptionsList.length > 1,
				activeDrawerOptions:
					state.drawerOptionsList.length > 1
						? state.drawerOptionsList[state.drawerOptionsList.length - 2]
						: undefined,
				drawerOptionsList: state.drawerOptionsList.slice(0, -1)
			};
		case 'closeAll':
			return initialState;
		default:
			return state;
	}
};

const TIMEOUT_CLOSING_DURATION = 100;

export const DrawerProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
	const [state, dispatch] = useReducer(drawerReducer, initialState);
	const { closeCallback, ...drawerProperties } = state?.activeDrawerOptions?.drawerProps || {};
	const bodyRef = createRef<HTMLDivElement>();

	const getActiveDrawerIndex = useCallback(() => {
		return state.drawerOptionsList.findIndex((opts) =>
			areObjectsEqual(opts, state.activeDrawerOptions)
		);
	}, [state.activeDrawerOptions, state.drawerOptionsList]);

	const openDrawer = useCallback((opts: DrawerOptions) => {
		dispatch({ type: 'open', payload: opts });
	}, []);

	const getClosingDrawerDuration = useCallback((): number => {
		return state.activeDrawerOptions?.drawerProps?.transitionDuration
			? isObject(state.activeDrawerOptions?.drawerProps?.transitionDuration)
				? state.activeDrawerOptions?.drawerProps?.transitionDuration?.exit || 0
				: state.activeDrawerOptions?.drawerProps?.transitionDuration || 0
			: TIMEOUT_CLOSING_DURATION;
	}, [state.activeDrawerOptions?.drawerProps?.transitionDuration]);

	const closeDrawer = useCallback(
		(opts: CloseOptions = { omitTimeout: false }) => {
			if (opts.omitTimeout) {
				closeCallback?.();
				dispatch({ type: 'close' });
			} else {
				let subscription: Subscription;

				if (state.isOpen && !isNil(state.activeDrawerOptions)) {
					const timeoutDuration = getClosingDrawerDuration();
					subscription = timer(timeoutDuration).subscribe(() => {
						dispatch({ type: 'close' });
					});
				}

				return () => {
					subscription.unsubscribe();
				};
			}
		},
		[closeCallback, getClosingDrawerDuration, state.activeDrawerOptions, state.isOpen]
	);

	const closeAll = useCallback(
		(opts: CloseOptions = { omitTimeout: false }) => {
			if (opts.omitTimeout) {
				closeCallback?.();
				dispatch({ type: 'closeAll' });
			} else {
				let subscription: Subscription;
				if (state.isOpen && !isNil(state.activeDrawerOptions)) {
					const timeoutDuration = getClosingDrawerDuration();
					subscription = timer(timeoutDuration).subscribe(() => {
						closeCallback?.();
						dispatch({ type: 'closeAll' });
					});
				}

				return () => {
					subscription.unsubscribe();
				};
			}
		},
		[closeCallback, getClosingDrawerDuration, state.activeDrawerOptions, state.isOpen]
	);

	const contextValue = useMemo<DrawerProperties>(
		() => ({
			open: openDrawer,
			close: closeDrawer,
			isOpen: state.isOpen,
			closeAll: (opts?: CloseOptions) => closeAll(opts),
			bodyRef
		}),
		[bodyRef, closeAll, closeDrawer, openDrawer, state.isOpen]
	);

	return (
		<drawerContext.Provider value={contextValue}>
			<Drawer
				open={state.isOpen}
				onClose={() => closeAll()}
				{...drawerProperties}
				sx={{ zIndex: zIndex.drawer }}>
				<DrawerHeader
					activeDrawerIndex={getActiveDrawerIndex()}
					onIconClick={closeDrawer}
					closeXButton={state.activeDrawerOptions?.closeXButton ?? false}
				/>
				<DrawerBodyContainer ref={bodyRef}>
					{state.activeDrawerOptions?.drawerBodyComponent}
				</DrawerBodyContainer>
				{state.activeDrawerOptions?.drawerFooterComponent && (
					<DrawerFooterContainer>
						{state.activeDrawerOptions.drawerFooterComponent}
					</DrawerFooterContainer>
				)}
			</Drawer>
			{children}
		</drawerContext.Provider>
	);
};
