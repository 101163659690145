import {
	areGoogleMapAutocompleteItemsEqual,
	formatCoordinates,
	GoogleMapAutocompleteItem,
	isItemAutocompletePrediction,
	isItemCoordinates,
	isItemPlace,
	isLoading,
	isNil,
	Maybe
} from '@/shared';
import { useGoogleMapSuggestions } from '@/ui/autocomplete/google-map/helpers/useGoogleMapSuggestions';
import Autocomplete from '@mui/material/Autocomplete';
import { FC, ReactNode, SyntheticEvent, useMemo, useState } from 'react';
import { GoogleMapAutocompleteOption } from '@/ui/autocomplete/google-map/helpers/GoogleMapAutocompleteOption';
import { OverridableStringUnion } from '@mui/types';
import { TextFieldPropsSizeOverrides } from '@mui/material/TextField/TextField';
import { Input } from '@/ui/input/Input';
import { CircularProgress } from '@mui/material';
import PinStroke from '@/assets/icons/generated/stroke/PinStroke';
import { PaperProps } from '@mui/material/Paper';

export type SearchType = 'cities' | 'places';

type GoogleMapAutocompleteProps = {
	onChange?: (value: Maybe<GoogleMapAutocompleteItem>) => void;
	inputPlaceholder?: string;
	size?: OverridableStringUnion<'small' | 'medium', TextFieldPropsSizeOverrides>;
	id?: string;
	searchType?: SearchType;
	value?: GoogleMapAutocompleteItem;
	includeInputInList?: boolean;
	onPromptStateChange?: (value: boolean) => void;
	paperProps?: PaperProps;
	disabled?: boolean;
};
export const GoogleMapAutocomplete: FC<GoogleMapAutocompleteProps> = ({
	inputPlaceholder,
	size = 'medium',
	onChange = () => {},
	id,
	searchType = 'cities',
	value,
	includeInputInList = true,
	onPromptStateChange = () => {},
	paperProps = {},
	disabled = false
}) => {
	const [query, setQuery] = useState<string>();

	const { options: googleMapOptions, loader } = useGoogleMapSuggestions(
		query,
		searchType,
		includeInputInList
	);
	const options = useMemo((): readonly GoogleMapAutocompleteItem[] => {
		const defaultOptions = googleMapOptions || [];
		return !isNil(value) ? [value, ...defaultOptions] : defaultOptions;
	}, [googleMapOptions, value]);

	return (
		<Autocomplete
			id={id || 'GoogleMapAutocompleteId'}
			disabled={disabled}
			options={options}
			autoComplete
			freeSolo
			includeInputInList={includeInputInList}
			getOptionLabel={(option: GoogleMapAutocompleteItem) =>
				isItemAutocompletePrediction(option)
					? option.structured_formatting.main_text
					: isItemCoordinates(option)
					? formatCoordinates(option)
					: isItemPlace(option)
					? option.details?.name || formatCoordinates(option.coordinates)
					: option
			}
			value={value || ''}
			onInputChange={(_, newQuery) => setQuery(newQuery)}
			isOptionEqualToValue={(receivedOption, receivedValue) =>
				areGoogleMapAutocompleteItemsEqual(receivedOption, receivedValue)
			}
			onChange={(event: SyntheticEvent, prediction: Maybe<GoogleMapAutocompleteItem>) => {
				onChange(prediction);
			}}
			renderOption={(props, option: GoogleMapAutocompleteItem, state) => (
				<GoogleMapAutocompleteOption key={state.index} item={option} htmlProps={props} />
			)}
			popupIcon={isLoading([loader]) && <CircularProgress color="primary" size={20} />}
			loading={isLoading([loader])}
			onOpen={() => onPromptStateChange(true)}
			onClose={() => {
				onPromptStateChange(false);
			}}
			slotProps={{
				paper: {
					...paperProps,
					sx: {
						marginY: 1,
						...paperProps.sx
					}
				}
			}}
			renderInput={(params) => (
				<Input
					{...params}
					size={size}
					placeholder={inputPlaceholder}
					leftIcon={PinStroke}
					InputLabelProps={{
						children: params.InputLabelProps.children as ReactNode
					}}
				/>
			)}
		/>
	);
};
