import { isLoading, Loader } from '@/shared';
import { useEffect } from 'react';

export const useLoaderHandler = (
	loaders: Loader[],
	data?: unknown[],
	failCallback?: () => void,
	successCallback?: (data?: unknown[]) => void
): boolean => {
	useEffect(() => {
		if (!isLoading(loaders) && data?.some((val) => val === undefined)) {
			failCallback?.();
		} else if (!isLoading(loaders) && !data?.some((val) => val === undefined)) {
			successCallback?.(data);
		}
	}, [failCallback, data, loaders, successCallback]);

	return isLoading(loaders);
};
