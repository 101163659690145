import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSurfingStroke = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props?.width || '24px'}
		height={props?.height || '24px'}
		fill={props?.color || 'black'}
		viewBox="0 0 25 24"
		stroke={props?.stroke || 'none'}
		{...props}>
		<path
			fill="currentColor"
			d="M19.336 5.75a1.875 1.875 0 0 1-1.875-1.875c0-1.016.86-1.875 1.875-1.875 1.055 0 1.875.86 1.875 1.914 0 1.016-.82 1.836-1.875 1.836Zm1.64 6.055-3.75-2.852-3.203 1.485 2.461 1.874c.391.313.586.782.586 1.25v.313l-.86 3.945c-.116.47-.468.742-.898.742l4.063 1.485c.156.078.352.078.508.078.547 0 .625-.195 1.015-.195.274 0 .977.195.977.898 0 .899-1.133 1.172-1.992 1.172-.39 0-.781-.04-1.172-.195L4.492 16.609C3.008 16.102 2.5 14.812 2.5 14.5c0-.273.195-.977.898-.977.391 0 .704.235.86.586.195.352.469.625.86.743l1.718.625a.88.88 0 0 1-.313-.625c0-.391.235-.743.586-.899l3.008-1.21a.355.355 0 0 0 .156-.313V10.28c0-.82.47-1.562 1.211-1.953l2.305-1.172-1.367-.468c-.43-.079-.703-.352-.899-.743l-1.445-2.539a.788.788 0 0 1-.117-.43c0-.585.469-.937.937-.937.352 0 .665.156.82.469l1.407 2.5 2.617.86a5.36 5.36 0 0 1 1.68.898l4.687 3.554c.235.196.391.47.391.742a.96.96 0 0 1-.195.586.983.983 0 0 1-.782.352c-.195 0-.39-.04-.546-.195Zm-5.78 1.836-3.048-2.266v1.055c0 .898-.507 1.68-1.328 2.03l-2.968 1.25c-.118.04-.196.04-.313.04l7.149 2.617c-.196-.195-.352-.469-.352-.742 0-.04.039-.117.039-.195l.82-3.79Z"
		/>
	</svg>
);
export default SvgSurfingStroke;
