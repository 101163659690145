import { useAuth0 } from '@auth0/auth0-react';
import { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';
import { hasTrainerRole, isNil, Loader, Maybe, MeTrainer, useObservable } from '@/shared';
import { NEVER } from 'rxjs';
import { useClient, useToken } from '@/config';
import { useSelfTrainerRepository } from '@/shared/repository/trainer/use-self-trainer-repository';

type TrainerContextProperties = {
	me: Maybe<MeTrainer>;
	loader: Loader;
};

export const trainerContext = createContext<TrainerContextProperties>({
	me: undefined,
	loader: {
		loading: false
	}
});

/**
 * @deprecated use useTrainer from shared/hooks
 */
export const useTrainer = () => {
	return useContext(trainerContext);
};

export const TrainerProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
	const { tokenReady } = useToken();
	const { me: client, loader: clientLoader } = useClient();
	const { getMe } = useSelfTrainerRepository();
	const { isAuthenticated, user } = useAuth0();

	const me$ = useMemo(() => {
		if (
			tokenReady &&
			isAuthenticated &&
			hasTrainerRole(user) &&
			!isNil(client) &&
			!clientLoader.loading
		) {
			return getMe();
		}
		return NEVER;
	}, [client, clientLoader.loading, getMe, isAuthenticated, tokenReady, user]);

	const { value: me, loader } = useObservable<MeTrainer>(me$);

	const contextValue = useMemo<TrainerContextProperties>(
		() => ({
			me: me,
			loader
		}),
		[loader, me]
	);

	return <trainerContext.Provider value={contextValue}>{children}</trainerContext.Provider>;
};
