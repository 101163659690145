import { useContext } from 'react';
import { isNil } from '@/shared';
import { trainerBalanceContext } from '@/config/trainer/trainer-balance-context';
import { FallbackProps } from '@/config/hoc/with-fallback';
import { useLoaderHandler } from '@/shared/utils/loader/use-loader-handler';

export const useTrainerBalance = (fallback?: FallbackProps) => {
	const context = useContext(trainerBalanceContext);

	if (isNil(context)) {
		throw new Error('useTrainerBalance must be used within TrainerBalanceContext.Provider');
	}

	const loading = useLoaderHandler(
		[context.loader],
		[context.trainerBalance],
		() => fallback?.onError(),
		() => fallback?.onSuccess()
	);

	return {
		trainerBalance: context?.trainerBalance,
		loading
	};
};
