import {
	BadmintonFilled,
	BasketballFilled,
	BoxFilled,
	CrossfitFilled,
	CyclingFilled,
	EmsFilled,
	FitnessFilled,
	FootballFilled,
	GolfFilled,
	GymFilled,
	HealthySpineFilled,
	HockeyFilled,
	HorseRidingFilled,
	KettlebellFilled,
	KitesurfingFilled,
	MartialArtsFilled,
	MuayThaiFilled,
	PilatesFilled,
	QuestionSolid,
	ReformerPilatesFilled,
	RunningFilled,
	SkiingFilled,
	SquashFilled,
	StepFilled,
	SurfingFilled,
	SwimmingFilled,
	TableTennisFilled,
	TennisFilled,
	VolleyballFilled,
	WindsurfingFilled,
	YogaFilled
} from '@/assets/icons/generated/filled';
import {
	BadmintonStroke,
	BasketballStroke,
	BoxingStroke,
	CrossfitStroke,
	CyclingStroke,
	EmsStroke,
	FitnessStroke,
	FootballStroke,
	GolfStroke,
	GymStroke,
	HealthySpineStroke,
	HockeyStroke,
	HorseRidingStroke,
	KitesurfingStroke,
	MartialArtsStroke,
	MuayThaiStroke,
	PilatesStroke,
	ReformerPilatesStroke,
	RunningStroke,
	SkiingStroke,
	SquashStroke,
	StepStroke,
	SurfingStroke,
	SwimmingStroke,
	TableTennisStroke,
	TennisStroke,
	VolleyballStroke,
	WindsurfingStroke,
	YogaStroke
} from '@/assets/icons/generated/stroke';
import { TranslationKey } from '@/config';
import { IconType } from '@/shared';

export type Discipline =
	| 'SWIMMING'
	| 'TENNIS'
	| 'RUNNING'
	| 'FOOTBALL'
	| 'VOLLEYBALL'
	| 'BASKETBALL'
	| 'CYCLING'
	| 'GOLF'
	| 'GYM'
	| 'CROSSFIT'
	| 'SURFING'
	| 'WINDSURFING'
	| 'KITESURFING'
	| 'FITNESS'
	| 'SQUASH'
	| 'EMS'
	| 'YOGA'
	| 'BOX'
	| 'HORSE_RIDING'
	| 'MUAY_THAI'
	| 'TABLE_TENNIS'
	| 'BADMINTON'
	| 'HOCKEY'
	| 'MARTIAL_ARTS'
	| 'SKIING'
	| 'KETTLEBELL'
	| 'HEALTHY_SPINE'
	| 'REFORMER_PILATES'
	| 'PILATES'
	| 'STEP';

export type IconDetails = {
	filledIcon: IconType;
	strokeIcon: IconType;
	translationKey: TranslationKey;
};

export const disciplineIcons: Record<Discipline, IconDetails> = {
	SWIMMING: {
		filledIcon: SwimmingFilled,
		strokeIcon: SwimmingStroke,
		translationKey: 'types.discipline.swimming'
	},
	TENNIS: {
		filledIcon: TennisFilled,
		strokeIcon: TennisStroke,
		translationKey: 'types.discipline.tennis'
	},
	RUNNING: {
		filledIcon: RunningFilled,
		strokeIcon: RunningStroke,
		translationKey: 'types.discipline.running'
	},
	FOOTBALL: {
		filledIcon: FootballFilled,
		strokeIcon: FootballStroke,
		translationKey: 'types.discipline.football'
	},
	VOLLEYBALL: {
		filledIcon: VolleyballFilled,
		strokeIcon: VolleyballStroke,
		translationKey: 'types.discipline.volleyball'
	},
	BASKETBALL: {
		filledIcon: BasketballFilled,
		strokeIcon: BasketballStroke,
		translationKey: 'types.discipline.basketball'
	},
	CYCLING: {
		filledIcon: CyclingFilled,
		strokeIcon: CyclingStroke,
		translationKey: 'types.discipline.cycling'
	},
	GOLF: {
		filledIcon: GolfFilled,
		strokeIcon: GolfStroke,
		translationKey: 'types.discipline.golf'
	},
	GYM: {
		filledIcon: GymFilled,
		strokeIcon: GymStroke,
		translationKey: 'types.discipline.gym'
	},
	CROSSFIT: {
		filledIcon: CrossfitFilled,
		strokeIcon: CrossfitStroke,
		translationKey: 'types.discipline.crossfit'
	},
	SURFING: {
		filledIcon: SurfingFilled,
		strokeIcon: SurfingStroke,
		translationKey: 'types.discipline.surfing'
	},
	WINDSURFING: {
		filledIcon: WindsurfingFilled,
		strokeIcon: WindsurfingStroke,
		translationKey: 'types.discipline.windsurfing'
	},
	KITESURFING: {
		filledIcon: KitesurfingFilled,
		strokeIcon: KitesurfingStroke,
		translationKey: 'types.discipline.kitesurfing'
	},
	FITNESS: {
		filledIcon: FitnessFilled,
		strokeIcon: FitnessStroke,
		translationKey: 'types.discipline.fitness'
	},
	SQUASH: {
		filledIcon: SquashFilled,
		strokeIcon: SquashStroke,
		translationKey: 'types.discipline.squash'
	},
	BOX: {
		filledIcon: BoxFilled,
		strokeIcon: BoxingStroke,
		translationKey: 'types.discipline.box'
	},
	EMS: {
		filledIcon: EmsFilled,
		strokeIcon: EmsStroke,
		translationKey: 'types.discipline.ems'
	},
	YOGA: {
		filledIcon: YogaFilled,
		strokeIcon: YogaStroke,
		translationKey: 'types.discipline.yoga'
	},
	HORSE_RIDING: {
		filledIcon: HorseRidingFilled,
		strokeIcon: HorseRidingStroke,
		translationKey: 'types.discipline.horse-riding'
	},
	MUAY_THAI: {
		filledIcon: MuayThaiFilled,
		strokeIcon: MuayThaiStroke,
		translationKey: 'types.discipline.muay-thai'
	},
	HOCKEY: {
		filledIcon: HockeyFilled,
		strokeIcon: HockeyStroke,
		translationKey: 'types.discipline.hockey'
	},
	BADMINTON: {
		filledIcon: BadmintonFilled,
		strokeIcon: BadmintonStroke,
		translationKey: 'types.discipline.badminton'
	},
	MARTIAL_ARTS: {
		filledIcon: MartialArtsFilled,
		strokeIcon: MartialArtsStroke,
		translationKey: 'types.discipline.martial-arts'
	},
	TABLE_TENNIS: {
		filledIcon: TableTennisFilled,
		strokeIcon: TableTennisStroke,
		translationKey: 'types.discipline.table-tennis'
	},
	SKIING: {
		filledIcon: SkiingFilled,
		strokeIcon: SkiingStroke,
		translationKey: 'types.discipline.skiing'
	},
	KETTLEBELL: {
		filledIcon: KettlebellFilled,
		strokeIcon: BasketballStroke,
		translationKey: 'types.discipline.kettlebell'
	},
	HEALTHY_SPINE: {
		filledIcon: HealthySpineFilled,
		strokeIcon: HealthySpineStroke,
		translationKey: 'types.discipline.healthy-spine'
	},
	REFORMER_PILATES: {
		filledIcon: ReformerPilatesFilled,
		strokeIcon: ReformerPilatesStroke,
		translationKey: 'types.discipline.reformed-pilates'
	},
	PILATES: {
		filledIcon: PilatesFilled,
		strokeIcon: PilatesStroke,
		translationKey: 'types.discipline.pilates'
	},
	STEP: {
		filledIcon: StepFilled,
		strokeIcon: StepStroke,
		translationKey: 'types.discipline.step'
	}
};

export const disciplines = Object.keys(disciplineIcons) as Discipline[];

export const getDisciplineMetaData = (discipline: Discipline): IconDetails => {
	return disciplineIcons[discipline.toUpperCase()] || QuestionSolid;
};
