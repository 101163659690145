import { Flex } from '@/ui/flex/Flex';
import Typography from '@mui/material/Typography';
import { useTranslation } from '@/config';
import { SearchBar } from '@/config/layout/navigation/helpers/SearchBar';
import { useDrawer } from '@/ui/drawer/drawer-context';

export const SearchDrawer = () => {
	const { t } = useTranslation();
	const { close: closeDrawer } = useDrawer();

	return (
		<Flex height={'100%'} width={'100%'} flexDirection={'column'} gap={2}>
			<Flex width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
				<Typography variant={'h3'}>{t('shared.search')}</Typography>
				<Typography
					onClick={() => closeDrawer}
					variant={'p2'}
					sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
					{t('shared.cancel')}
				</Typography>
			</Flex>
			<SearchBar onSearch={closeDrawer} />
		</Flex>
	);
};
